// $primary-color: #07b27e;
$primary-color: #0d9b84;
$accent-color: #051d30;
$secondary-color: #0d314d;
// $alert-color: #ba3939;
$alert-color: #f44336;
$white-color: #fff;
$devider-color: #202042;
$box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.42);
$admin-background: var(--Linear, linear-gradient(180deg, #0d314d 0%, #051d30 100%));
// $admin-background: #f6faf9;
$border: #ccc;
$background-light: #f9f9f9;
$overlay: rgba(15, 15, 16, 0.2);
$snackbar-bg: #323232;
$text-color: #07213c;
$icons-color: #77797b;
$shapes-color: #ddeaf3;
$lines-color: #c0c0c0;
$scrollbar-color: rgba(80, 153, 209, 0.04);
$secondary-color-opacity-5: rgba(100, 169, 222, 0.3);
$scroll-color: #e5e7e8;
$chat-color: #4776a7;
$border-color: #99acc4;
$font-family-en: "Roboto", sans-serif;
$font-family-ar: "Tajawal", sans-serif;
// $font-family-en: "Source Sans Pro", sans-serif;
// $font-family-ar: "Almarai", sans-serif;
:root {
  // --secondary-color: #0d314d;
  // --primary-color: #07b27e;
  --secondary-color: #0d314d;
  --primary-color: #0d9b84;
  // --alert-color: #ba3939;
  --alert-color: #f44336;

  --devider-color: #202042;
  --accent-color: #051d30;
}

// #e6a512
// rgba(230, 165, 18, 0.5);
// rgba(230, 165, 18, 0.04);
