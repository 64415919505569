$fontSize: 16;

html {
  font-size: $fontSize + px;
  scroll-behavior: smooth;
  &[lang="en"] {
    font-family: $font-family-en, sans-serif !important;
    direction: ltr !important;
  }
  &[lang="ar"] {
    font-family: $font-family-ar, sans-serif !important;
    direction: rtl !important;
  }
}

/* Styles for large screens */
@media (min-width: 1200px) {
  $fontSize: 10.5;
  html {
    font-size: $fontSize + px !important;
  }
}

/* Styles for medium screens */
@media (max-width: 1199px) {
  $fontSize: 10;
  html {
    font-size: $fontSize + px !important;
  }
}

/* Styles for small screens */
@media (max-width: 576px) {
  $fontSize: 13.5;
  html {
    font-size: $fontSize + px !important;
  }
}

/* Styles for medium screens */
@media (min-width: 577px) and (max-width: 768px) {
  $fontSize: 13;
  html {
    font-size: $fontSize + px !important;
  }
}

// convert px to rem
@function pxToRem($pxSize) {
  $remValue: calc($pxSize / $fontSize);
  @return $remValue + rem;
}

@mixin textCustom($size, $line-height, $weight) {
  font-size: pxToRem($size) !important;
  line-height: pxToRem($line-height) !important;
  font-weight: $weight !important;
  font-family: $font-family-ar;
  letter-spacing: 0 !important;
}
