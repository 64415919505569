::ng-deep {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mat-mdc-form-field-infix {
    min-height: pxToRem(48) !important;
    height: pxToRem(48) !important;
  }

  .mat-mdc-form-field-flex {
    display: flex;
    align-items: center;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding: pxToRem(6) 0 !important;
    display: flex;
    align-items: center;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-radius: pxToRem(8) !important;
    border-width: 1px !important;
    border-color: $icons-color !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    border-radius: pxToRem(8) !important;
    border-width: 1px !important;
    border-color: $primary-color !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mdc-text-field--focused .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    caret-color: $primary-color !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-text-field--focused
    .mdc-notched-outline
    .mdc-notched-outline__leading {
    border-color: $primary-color !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    height: 100%;
  }
  .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input {
    caret-color: $alert-color !important;
  }
  .mdc-text-field--outlined .mdc-notched-outline,
  .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
  .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading {
    border-color: $alert-color !important;
    border-radius: pxToRem(8) !important;
    border-width: 1px !important;
  }

  .mdc-notched-outline__leading {
    display: none;
  }
  .mdc-notched-outline__trailing {
    border-inline-start: 1px solid !important;
    border-radius: pxToRem(8) !important;
  }
  .mat-mdc-form-field-error {
    margin-top: pxToRem(8) !important;
    color: $alert-color !important;
  }

  .mat-mdc-form-field-bottom-align::before {
    height: auto !important;
  }

  .mat-mdc-button .mat-mdc-button-persistent-ripple::before {
    background-color: transparent !important;
  }

  .mat-mdc-button .mat-mdc-button-ripple,
  .mat-mdc-unelevated-button .mat-mdc-button-ripple,
  .mat-mdc-raised-button .mat-mdc-button-ripple,
  .mat-mdc-outlined-button .mat-mdc-button-ripple,
  .mat-mdc-button .mat-mdc-button-touch-target {
    display: none !important;
    visibility: hidden !important;
  }
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 1rem !important;
    overflow: hidden;
    @media only screen and (max-width: 768px) {
      height: auto !important;
    }
  }
  @media only screen and (max-width: 768px) {
    .dialog-container {
      padding: 1rem !important;
    }
    .mdc-dialog .mdc-dialog__content {
      padding: 0 !important;
      height: 60vh;
    }
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate="true"]:enabled ~ .mdc-checkbox__background {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
  }

  .mat-mdc-button[disabled][disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
}
